
import { Component, ErrorInfo }  from 'react';
import { Notifier } from '@airbrake/browser';
import { Error } from "../Error";
import { Filter } from '@airbrake/browser/dist/filter/filter';

interface NotifierParams {
  error: Error;
  params: { info: ErrorInfo };
}

type AirbrakeType = {
  addFilter: (filter: Filter) => void;
  notify: (_: NotifierParams) => void;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

// Set Airbrake configuration
const projectId = 509636;
const projectKey = '396774a5ab6be083acc3223cb3ae5315';

class ErrorBoundary extends Component<any, ErrorBoundaryState> {
  private airbrake: AirbrakeType;

  constructor(props: unknown) {
    super(props);
    this.state = { hasError: false };
    this.airbrake = new Notifier({ projectId: +projectId, projectKey });
    this.airbrake.addFilter((filter) => {
      if (process.env.NODE_ENV !== 'production') return null;
      return filter;
    });
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // Display fallback UI
    this.setState({ hasError: true });
    // Send error to Airbrake
    this.airbrake.notify({
      error,
      params: { info },
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Error />
      );
    }

    return children;
  }
}

export default ErrorBoundary;