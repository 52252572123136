import * as React from 'react';

function ShieldIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M1.5 2.615v5.076a7.938 7.938 0 005.088 7.41l.694.266a2 2 0 001.436 0l.694-.267A7.938 7.938 0 0014.5
        7.691V2.615a.991.991 0 00-.579-.908A14.467 14.467 0 008 .5a14.467 14.467 0 00-5.921 1.207.991.991 0
        00-.579.908z"
        stroke="#0E0A2B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.467 7.5A3.5 3.5 0 118 3.5M11.5 4.375l-3.19 3.19a.438.438 0 01-.62 0l-1-1.003"
        stroke="#0E0A2B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default ShieldIcon;
