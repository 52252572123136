import { Box } from "@twilio-paste/box";
import { Spinner } from "@twilio-paste/spinner";

type Props = {
  title: string;
};

export const Loading = ({ title }: Props) => (
  <Box width="100%" display="flex" justifyContent="center" marginY="space80">
    <Spinner decorative={false} title={title} size="sizeIcon100" />
  </Box>
);
