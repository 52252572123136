import { ReactNode } from "react";
import styles from "./Section.module.css";

export type SectionProps = {
  children?: ReactNode;
  color?: "light" | "dark";
};

export const Section = ({ children, color }: SectionProps) => {
  let classes = `${styles.section}`;

  // Set background colour
  if (color) {
    classes += ` ${styles[color]}`;
  }

  return <div className={classes}>{children}</div>;
};
