import { useLocation } from "react-router-dom";
import { Box } from "@twilio-paste/box";
import { Stack } from "@twilio-paste/stack";
import { Text } from "@twilio-paste/text";
import { useCustomerDetails } from "./hooks/useCustomerDetails";
import { Error } from "./components/Error";
import { Loading } from "./components/Loading";
import { PolicyDetailCard } from "./components/PolicyDetailCard";
import { Section } from "./components/Section";
import { InfoHeader } from "./components/InfoHeader";
import { COIBox } from "./components/COIBox";
import { useEffect, useRef } from "react";
import { getSnowplowContexts, snowplow } from "@services/analytics";
import { Policy } from "./types/policy";
import { businessUnit } from "./config/constants";

const App = () => {
  const initialised = useRef(false);
  const { pathname } = useLocation();
  const identityId = pathname.slice(1);

  useEffect(() => {
    if (!initialised.current) {
      const contexts = getSnowplowContexts({ site: businessUnit.replace("_uk", "") });
      snowplow.trackPageView(contexts);
      initialised.current = true;
    }
  });
  const { isLoading, error, data } = useCustomerDetails(identityId);

  if (error) {
    return <Error />;
  }

  if (isLoading) {
    return <Loading title="Loading policy information" />;
  }

  const multiplePolicies = data.length > 1;

  return (
    <>
      <InfoHeader />
      { data.map((policy: Policy, index: number) => {
        
        const { coiLink, policyStatus, policyNumber } = policy;
        const lastPolicy = index === data.length-1;
        const showCOIButton = policyStatus === "active" || policyStatus === "pending";

        return (
          <Section key={policyNumber}>
            <Box margin="space40">
              <Stack orientation="vertical" spacing="space40">
                <PolicyDetailCard {...policy} />
              </Stack>
              { showCOIButton ? (
                <COIBox coiLink={coiLink} multiplePolicies={multiplePolicies} />
              ) : (
                <Text
                  as="p"
                  paddingTop="space80"
                  paddingBottom={ lastPolicy ? "space60" : "space160" }
                  textAlign="center"
                >
                  This policy is no longer active, so we cannot provide a valid
                  Certificate of Insurance.
                </Text>
              )}
            </Box>
          </Section>
        );
      })}
    </>
  );
};

export default App;
