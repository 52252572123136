import * as React from 'react';

function CalendarIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.501}
        y={2.5}
        width={15}
        height={13}
        rx={1.5}
        stroke="#0E0A2B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M.501 6.5h15M4.501 4V.5M11.502 4V.5"
        stroke="#0E0A2B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default CalendarIcon;
