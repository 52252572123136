import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CustomizationProvider } from "@twilio-paste/customization";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import App from "./App";
import CustomTheme from "./themes/theme.json";
import CustomElements from "./themes/customElements";
import "./styles/globals.css";
import "./styles/fonts.css";
import pkg from "../package.json";
// import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient();

const rootElement = document.getElementById("root") as HTMLElement;
// Add version to DOM
rootElement.setAttribute("data-version", pkg.version);

ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <CustomizationProvider
          baseTheme="default"
          // @ts-expect-error icon sizes in theme
          theme={CustomTheme}
          // @ts-expect-error BUTTON css
          elements={CustomElements}
        >
          <ErrorBoundary>
            <Header />
            <App />
            <Footer />
          </ErrorBoundary>
        </CustomizationProvider>
      </Router>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
