import { useQuery } from "@tanstack/react-query";
import mockCustomerDetails from "../mocks/mockCustomerDetails";
import { API_VERSION, getApiBaseUrl } from "../config/api";
import { businessUnit } from "src/config/constants";

export const useCustomerDetails = (identityId?: string) => {
  const idWithoutSlashes = identityId?.replace(/\//i, "");
  const { isLoading, error, data } = useQuery({
    queryKey: ["coi"],
    queryFn: () => {
      // Handle empty case - do not try to query
      if (!idWithoutSlashes) {
        throw Error("No identity id supplied");
      }
      // Handle mock case
      if (idWithoutSlashes === "1111-22222" && process.env.NODE_ENV !== "production") {
        return mockCustomerDetails;
      }

      return fetch(`${getApiBaseUrl()}/${API_VERSION}/proof_of_insurance/${idWithoutSlashes}`, {
        headers: {
          BUSINESS_UNIT_CODE: businessUnit,
        },
      }).then((res) => res.json());
    },
  });

  return { isLoading, error, data };
};
