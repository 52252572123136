import { PolicyDetailRow } from "../PolicyDetailRow";
import ShieldIcon from "../Icons/ShieldIcon";
import SilhouetteIcon from "../Icons/SilhouetteIcon";
import CalendarIcon from "../Icons/CalendarIcon";
import { Text } from "@twilio-paste/text";
import { Box } from "@twilio-paste/box";
import { Stack } from "@twilio-paste/stack";
import { Badge, BadgeProps } from "@twilio-paste/badge";
import { businessUnit } from "src/config/constants";

type PolicyDetailCardProps = {
  policyType: string;
  businessName: string;
  nameInsured: string;
  policyNumber: string;
  policyExpirationDate: string;
  policyStatus: string;
  policyStartDate: string;
};

const statusVariants: { [key: string]: BadgeProps["variant"] } = {
  "active": "success",
  "expired": "error",
  "cancelled": "error",
  "pending": "warning",
  "renewed": "success",
};

const localizedString = (dateString: string) => {
  const locale = businessUnit === "simplybusiness_us" ? "en-US" : "en-GB";

  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  return new Date(dateString).toLocaleDateString(locale, options);
}

export const PolicyDetailCard = ({
  policyType,
  businessName,
  nameInsured,
  policyNumber,
  policyExpirationDate,
  policyStatus,
  policyStartDate,
}: PolicyDetailCardProps) => {
  const badgeVariant = statusVariants[policyStatus];

  return (
    <Box
      backgroundColor="colorBackgroundDecorative30Weakest"
      padding="space60"
      borderRadius="borderRadius20"
    >
      <Text as="p" color="colorText" fontSize="fontSize20" marginBottom="space40">
        {businessName.toUpperCase()}
      </Text>
      <Badge as="span" variant={badgeVariant}>
        { badgeVariant === 'warning' 
          ? ( `POLICY START DATE: ${localizedString(policyStartDate)}`) 
          : ( policyStatus.toUpperCase() )
        }
      </Badge>
      <Text
        as="h1"
        color="colorText"
        fontSize="fontSize60"
        paddingTop="space60"
        paddingBottom="space30"
      >
        {policyType}
      </Text>
      <Stack orientation={["vertical", "horizontal"]} spacing="space20">
        <PolicyDetailRow
          Icon={<SilhouetteIcon />}
          headerText="Name insured"
          contentText={nameInsured}
        />
        <PolicyDetailRow
          Icon={<ShieldIcon />}
          headerText="Policy number"
          contentText={policyNumber}
        />
        <PolicyDetailRow
          Icon={<CalendarIcon />}
          headerText="Valid until"
          contentText={localizedString(policyExpirationDate)}
        />
      </Stack>
    </Box>
  );
};
