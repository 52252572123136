export const getApiBaseUrl = () => {
  if (process.env.NODE_ENV === "production") {
    return window.location.href.includes("staging") ?
      "https://account-api-gw-staging.simplybusiness.co.uk" :
      "https://account-api-gw.simplybusiness.co.uk";
  }
  return "http://localhost:4001";
}

export const API_VERSION = "v3";

const apiConfig = { getApiBaseUrl, API_VERSION };

export default apiConfig;
