import {
  newTracker,
  TrackerConfiguration,
  setCookiePath,
  setUserId,
  trackStructEvent,
  StructuredEvent,
  trackPageView,
} from "@snowplow/browser-tracker";
import { getEnvConfig } from "./config";
import { ChannelContexts, TrackingProps } from "./types";

const isDevEnv = process.env.NODE_ENV === "development";
const useTracking = !isDevEnv;

/**
 * This class is an abstraction which wraps Snowplow
 * and exposes common methods with other services:
 * - trackEvent    : sends a standard payload
 * - trackPageView : sends a page view
 */
export default class SnowplowTracker {
  // Namespaces
  bronzeAvalancheTrackerName = "sb-ava-br";

  constructor({
    appId,
    avalancheCollector,
    eventMethod,
    uid,
  }: TrackingProps) {
    if (useTracking) {
      // Set options
      const stateStorageStrategy = isDevEnv
        ? "localStorage"
        : "cookieAndLocalStorage";
      const baseOptions: TrackerConfiguration = {
        appId,
        eventMethod,
        stateStorageStrategy,
      };

      // Initialize trackers
      newTracker(
        this.bronzeAvalancheTrackerName,
        avalancheCollector,
        baseOptions
      );

      setCookiePath("/");
      if (uid) {
        setUserId(uid);
      }
    }
  }

  trackEvent(event: StructuredEvent) {
    trackStructEvent(event, [this.bronzeAvalancheTrackerName]);
    return this;
  }

  trackPageView(contexts: ChannelContexts) {
    if (useTracking) {
      const validatedContexts = Object.entries(contexts).map(
        ([, value]) => value
      );

      trackPageView({ context: validatedContexts });
    }
    return this;
  }
}

const snowplowConfig = getEnvConfig();
export const snowplow = new SnowplowTracker(snowplowConfig);

export * from "./config";
export * from "./contexts";
export * from "./event-definitions";