export const errorHeader = "Sorry, we're having a problem with our system.";

export const errorText = "Please refresh the page or come back soon. We'll fix this as soon as possible.";

const host = new URL(window.location.href).host;
const host_mapping: { [key: string]: string } = {
  "pocketproof.simplybusiness.com": "simplybusiness_us",
  "pocketproof.simplybusiness.co.uk": "simplybusiness_uk",
  "staging-deployment-us.pocketproof.pages.dev": "simplybusiness_us",
  "staging-deployment-uk.pocketproof.pages.dev": "simplybusiness_uk",
  "localhost:5173": "simplybusiness_us",
  "localhost": "simplybusiness_us", // for test env
};

export const businessUnit = host_mapping[host];