import { SBLogo } from "../SBLogo";
import { Section } from "../Section";
import styles from "./Header.module.css";

export const Header = () => {
  return (
    <header className={styles.container}>
      <Section>
        <div className={styles.innerWrapper}>
          <SBLogo />
        </div>
      </Section>
    </header>
  );
};
