import { snowplow } from ".";

type EventDefinition = Record<string, () => void>;

export const eventDefinitions: EventDefinition = {
  viewCOIClicked: () => {
    snowplow.trackEvent({
      category: "download_cta",
      action: "link_click",
      label: "Download COI",
    });
  },
  discoverSBClicked: () => {
    snowplow.trackEvent({
      category: "discover_cta",
      action: "link_click",
      label: "Discover SB",
    });
  },
};