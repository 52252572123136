import { businessUnit } from "src/config/constants";
import { BaseConfig, TrackingProps } from "./types";
/**
 * This file sets config for Snowplow tracking
 */

const domain = businessUnit === "simplybusiness_uk" ? ".co.uk" : ".com";
const appId = businessUnit === "simplybusiness_uk" ? "uk-pocketproof" : "us-pocketproof";

const collectorURL = window.location.href.includes("staging") ?
  `snowplow-collector-staging.simplybusiness${domain}` :
  `snowplow-collector.simplybusiness${domain}`;

const config: BaseConfig = {
  appId: appId,
  avalancheCollector: collectorURL,
  eventMethod: "post",
  trackPageView: true,
  trackActivity: true,
  includeGAContext: true,
};

export const getEnvConfig = (): TrackingProps => {
  const env = process.env.NODE_ENV;

  switch (env) {
    case "production":
      return {
        ...config,
      };
    case "test":
      return {
        ...config,
        trackPageView: false,
        avalancheCollector: "localhost:80",
      };
    // Dev
    default:
      return {
        ...config,
        trackPageView: false,
        avalancheCollector: "localhost:80",
      };
  }
};