import { Box, BoxProps } from "@twilio-paste/box";
import { Text } from "@twilio-paste/text";
import { Button } from "@twilio-paste/button";
import { eventDefinitions } from "@services/analytics";

export type COIBoxProps = {
  coiLink: string;
  multiplePolicies: boolean;
};

export const COIBox = ({ coiLink, multiplePolicies }: COIBoxProps) => {
  const { viewCOIClicked } = eventDefinitions;
  const boxProps: BoxProps = {
    paddingTop: multiplePolicies ? "space40" : "space100",
    paddingBottom: "space120",
    textAlign: "left"
  }

  return (
    <Box {...boxProps}>
      { !multiplePolicies && 
        <>
          <Text as="h3" fontSize="fontSize40">
            View Certificate of Insurance (COI)
          </Text>
          <Text as="p" paddingTop="space40" paddingBottom="space60">
            We get it - you want to make sure the business you're working with is
            properly insured. Take a look at their COI to confirm their coverage.
          </Text>
        </>
      }
      <Button
        variant="secondary"
        as="a"
        href={coiLink}
        onClick={viewCOIClicked}
      >
        View Certificate of Insurance
      </Button>
    </Box>
  );
};
