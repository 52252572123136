import { Text } from "@twilio-paste/text";
import { Box } from "@twilio-paste/box";

export type PolicyDetailRowProps = {
  Icon: JSX.Element;
  headerText: string;
  contentText: string;
};

export const PolicyDetailRow = ({
  Icon,
  headerText,
  contentText,
}: PolicyDetailRowProps) => {
  return (
    <Box
      white-space="nowrap"
      display="inline-flex"
      paddingTop="space30"
      width="size30"
    >
      {Icon}
      <Text as="span" paddingLeft="space40" color="colorText">
        {headerText}: {contentText}
      </Text>
    </Box>
  );
};
