import { Button } from "@twilio-paste/button";
import { Box } from "@twilio-paste/box";
import { Text } from "@twilio-paste/text";
import { eventDefinitions } from "@services/analytics";
import { businessUnit } from "../../config/constants";

export const Footer = () => {
  const { discoverSBClicked } = eventDefinitions;
  const domain = businessUnit === "simplybusiness_us" ? "com" : "co.uk";
  const year = new Date().getFullYear();

  return (
    <>
      <Box
        backgroundColor="colorBackgroundDecorative30Weakest"
        padding="space80"
        textAlign="left"
      >
        <Text as="h1" fontSize="fontSize60" color="colorText">
          About Simply Business
        </Text>
        <Text
          as="p"
          lineHeight="lineHeight30"
          color="colorText"
          paddingTop="space40"
          paddingBottom="space60"
        >
          Simply Business is changing the way small business owners
          find business insurance by offering customers tailored insurance coverage online.
        </Text>
        <Button
          variant="secondary"
          as="a"
          href={ `https://www.simplybusiness.${domain}` }
          onClick={discoverSBClicked}
        >
          Discover Simply Business
        </Button>
      </Box>

      <Text as="p" padding="space80">
        { businessUnit === 'simplybusiness_us' ? (
        `Copyright ${year} Simply Business. All Rights Reserved. Simply Business,
        Inc. is a licensed insurance producer in all U.S.States and the District
        of Columbia. Simply Business has its registered office at Simply
        Business, 1 Beacon Street, 15th Floor, Boston, MA, 02108. In the state
        of California, we operate under the name Simply Business Insurance
        Agency, Inc., License #0M20593. In the state of New York we operate
        under the name Simply Business Insurance Agency. In the state of Texas
        we operate under the name, U.S.Simply Business, Inc. For more
        information, please refer to our Privacy Policy and Terms & Conditions.`
          ) : (`
          Copyright ${year} Simply Business. All Rights Reserved.
           Simply Business is a trading name of Xbridge Limited which is authorised and 
           regulated by the Financial Conduct Authority
          (Financial Services Registration No: 313348).
          Xbridge Limited (No: 3967717) has its registered office at 6th Floor,
           99 Gresham Street, London, EC2V 7NG.`
        )}
      </Text>
    </>
  );
};
