const customer = [
  {
    businessName: "Harborway business",
    nameInsured: "Bruce Flatley",
    policyNumber: "HBW100001XB1",
    policyExpirationDate: "06/08/2024",
    policyType: "General Liability coverage",
    policyStatus: "active",
    policyStartDate: "07/14/2023",
    coiLink:
    "/assets/HBW100002XB1-Harborway business-Usa-Acord certificate of insurance.pdf",
  },
  // {
  //   businessName: "Harborway business",
  //   nameInsured: "Bruce Flatley",
  //   policyNumber: "HBW100001XB2",
  //   policyExpirationDate: "06/08/2024",
  //   policyType: "Cyber",
  //   policyStatus: "pending",
  //   policyStartDate: "07/14/2023",
  //   coiLink:
  //     "/assets/HBW100002XB1-Harborway business-Usa-Acord certificate of insurance.pdf",
  // },
  // {
  //   businessName: "Harborway business",
  //   nameInsured: "Bruce Flatley",
  //   policyNumber: "HBW100001XB3",
  //   policyExpirationDate: "06/08/2024",
  //   policyType: "Cyber",
  //   policyStatus: "expired",
  //   policyStartDate: "07/14/2023",
  //   coiLink:
  //     "/assets/HBW100002XB1-Harborway business-Usa-Acord certificate of insurance.pdf",
  // },
];

export default customer;
