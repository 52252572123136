import { Box } from "@twilio-paste/box";
import { Text } from "@twilio-paste/text";

export const InfoHeader = () => {
  return (
    <Box paddingRight="space80" paddingLeft="space80">
      <Box paddingTop="space80" paddingBottom="space20">
        <Text as="h1" fontSize="fontSize70" textAlign="left">
          Proof of insurance, on the go
        </Text>
      </Box>
      <Box paddingBottom="space40" paddingTop="space40">
        <Text as="p" lineHeight="lineHeight30">
          PocketProof by Simply Business is here to help you view all of the important details 
          about the business you're working with, including their certificate of insurance.
        </Text>
      </Box>
    </Box>
  );
};
