import { Text } from "@twilio-paste/text";
import { Box } from "@twilio-paste/box";
import { errorHeader, errorText } from "../../config/constants"

export const Error = () => (
  <Box maxWidth="100%" padding="space100">
    <Text
      as="p"
      fontSize="fontSize80"
      textAlign="center"
      paddingBottom="space100"
      fontWeight="fontWeightNormal"
      lineHeight="lineHeight70"
      paddingTop="space150"
    >
      {errorHeader}
    </Text>
    <Text as="p" fontSize="fontSize50" textAlign="center" fontWeight="fontWeightLight" paddingBottom="space200">
      {errorText}
    </Text>
  </Box>
);
