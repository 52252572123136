const theme = {
  BUTTON: {
    variants: {
      secondary: {
        backgroundColor: "colorBackgroundDecorative10Weakest",
        color: "colorTextWeakest",
        boxShadow: "none",
        ":hover": {
          backgroundColor: "colorBackgroundDecorative20Weakest",
          color: "colorTextWeakest",
          boxShadow: "none",
        },
      },
    },
  },
  BADGE: {
    color: "colorTextWeakest",
    boxShadow: "none",
    borderRadius: "borderRadiusPill",
    fontSize: "fontSize40",
    fontWeight: "normal",
    variants: {
      success: {
        backgroundColor: "colorBackgroundSuccess",
      },
      error: {
        backgroundColor: "colorBackgroundError",
      },
      warning: {
        backgroundColor: "colorBackgroundWarningWeakest",
        color: "colorText",
        fontSize: "fontSize30",
        fontWeight: "bold",
      },
    },
  },
};

export default theme;
