const SilhouetteIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 7.75C8.86396 7.75 10.375 6.23896 10.375 4.375C10.375 2.51104 8.86396 1 7 1C5.13604 1 3.625 2.51104 3.625 4.375C3.625 6.23896 5.13604 7.75 7 7.75Z"
      stroke="#0E0A2B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 14.5C12.596 11.4953 10.0317 9.25281 7 9.25281C3.96828 9.25281 1.40403 11.4953 1 14.5"
      stroke="#0E0A2B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SilhouetteIcon;
